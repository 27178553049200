import createRoutes from '@/api/index.js';

export default (context, inject) => {
    // This is the api calling function. We can change the http service in this function.
    const request = ({ method, url, params = null, data = null, headers = null }) => {
        if (!method) {
            console.error('API function call requires method argument');
            return;
        }

        if (!url) {
            console.error('API function call requires url argument');
            return;
        }

        return context.$axios({
            method,
            url,
            params,
            data,
            headers
        });
    };

    // Inject $api, the api calls will be done as this.$api.{module}.{functionName}()
    inject('api', createRoutes(request));
};
