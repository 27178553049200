import Ajv from 'ajv';
const ajv = new Ajv();

const validateAnalyticsSchema = (payload) => {
    const schema = {
        type: 'object',
        properties: {
            events: {
                type: 'object',
                properties: {
                    WE: { type: 'string', not: undefined, minLength: 1 },
                    GA: { type: 'string', not: undefined, minLength: 1 }
                },
                minProperties: 1
            },
            data: {
                type: 'object',
                minProperties: 2
            }
        },
        required: ['events', 'data'],
        additionalProperties: false
    };

    const validate = ajv.compile(schema);
    const valid = validate(payload);

    if (!valid) {
        if (process.env.SERVER_ENV === 'dev' || process.env.SERVER_ENV === 'local')
            alert('Oops Analytics Payload Issue! Fix Now!: ' + JSON.stringify(payload));
        throw new Error('Invalid Payload: ', payload);
    }
};

const renameUndefinedValues = (payload) => {
    const replacer = (key, val) => (typeof val === 'undefined' ? 'Not Available' : val);
    return JSON.parse(JSON.stringify(payload, replacer));
};

export { validateAnalyticsSchema, renameUndefinedValues };
