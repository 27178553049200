// import { ANALYTICS_PLATFORMS } from '~/static/constants.js';
import { validateAnalyticsSchema, renameUndefinedValues } from '~/services/jsonSchemaValidator.js';

/**
 * To Dos:
 * 1. Add Regex Check
 * 2. Make this extensible to other event capturers
 * 3. Logged IN check by checking if user info exists
 * 4. login(), logout() -> main events for known users
 */
const trackEvent = (tracker) => {
    if (
        process.env.SERVER_ENV === 'dev' ||
        process.env.SERVER_ENV === 'local' ||
        process.env.SERVER_ENV === 'localsecure'
    )
        console.log('Tracking: ', tracker);
    if (process.client) {
        try {
            // Rename all undefined string to 'Not Available'
            // Doing so will help us quickly understand data attributes are tracked incorrectly.
            tracker.data = renameUndefinedValues(tracker.data);
            validateAnalyticsSchema(tracker);

            if (tracker.events.WE) {
                // eslint-disable-next-line max-depth
                try {
                    webengage.track(tracker.events.WE, tracker.data);
                } catch (error) {
                    console.log('WebEngage Error: ', error.message);
                }
            }
            if (tracker.events.GA) {
                // eslint-disable-next-line max-depth
                if (tracker.data) {
                    window.gtag('event', tracker.events.GA, tracker.data);
                } else {
                    window.gtag('event', tracker.events.GA);
                }
            }
        } catch (error) {
            console.log('Error: ', error);
        }
    }
};

// Discuss this.
const trackLogin = (user) => {
    try {
        webengage.user.login(user.uuid);
        // webengage.user.setAttribute('uuid', user.uuid);
        // webengage.user.setAttribute('Email', user.primary_email);
        // webengage.user.setAttribute('name', user.name || user.first_name + ' ' + user.last_name);
        // webengage.user.setAttribute('username', user.username);
        // webengage.user.setAttribute('role_id', user.role_id);
        // webengage.user.setAttribute('level', user.level);
        // webengage.user.setAttribute('signup_platform', String(user.signup_platform_id));
        // webengage.user.setAttribute('whatsapp_opt_in', user.chatbot_consent);

        // const countryCode = user.country_code;
        // const phone = user.primary_phone;
        // if (countryCode && phone) {
        //     // If phone and country_code are present, only then update phone
        //     const phoneWithCountryCode = `${countryCode}${phone}`;
        //     webengage.user.setAttribute('phone', phoneWithCountryCode);
        // }
    } catch (error) {
        console.error('WebEngage Create User Error: ', error);
    }
};

const trackLogout = () => {
    try {
        webengage.user.logout();
    } catch (error) {
        console.error('Error: ', error);
    }
};
export { trackEvent, trackLogin, trackLogout };
