//
//
//
//
//
//

import Error404 from '../components/Error/Page404.vue';
import Error500 from '../components/Error/Page500.vue';

export default {
    name: 'Error',
    components: { Error404, Error500 },
    props: {
        error: {
            type: Object,
            default: null
        }
    },
    computed: {
        errorPage() {
            if (this.error.statusCode === 404) {
                return Error404;
            }
            // catch everything else
            return Error500;
        }
    }
};
